












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ToastProgrammatic as Toast, ModalProgrammatic as Modal } from 'buefy';
import TermsAndConditionForm from './TermsAndConditionForm.vue';
import { TermsAndConditionEditPayload } from '../../../store/modules/admin/types/admin.types';

@Component({})
export default class ViewTermsAndConditions extends Vue {
  @Prop() public items!: any;

  @Prop() public isRestoredVersion!: boolean;

  get tncDetails() {
    return this.items;
  }

  public updateTncDetails(items: any) {
    Modal.open({
      parent: this,
      component: TermsAndConditionForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        tncDetails: items,
        isRestoredVersion: this.isRestoredVersion
      },
      events: {
        update: (value: TermsAndConditionEditPayload) => {
          this.$emit('update', value);
          this.$emit('close');
        }
      }
    });
  }
}
