
























































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Model,
  Emit
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import { get as _get } from 'lodash';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from 'buefy';
import ViewTermsAndConditions from './ViewTermsAndConditions.vue';
import {
  TermsAndConditions,
  TermsAndConditionsValues,
  TermsAndConditionEditPayload,
  TermsAndConditionsStatus
} from '../../../store/modules/admin/types/admin.types';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { DialogProgrammatic as Dialog } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    BaseModal,
    ViewTermsAndConditions,
    SortableTableHeader
  }
})
export default class ViewHistoryTermsAndConditions extends Vue {
  sortHeaders = [
    { placeholder: 'Version', sortColumn: 'version', sort: true },
    { placeholder: 'Edited By', sortColumn: 'editedBy', sort: true },
    { placeholder: 'Status', sortColumn: 'status', sort: true }
  ];
  @Prop() public items!: TermsAndConditionsValues;
  @Prop() public title!: string;

  public currentTncVersion: TermsAndConditionsValues = this.items;
  public isRestoredVersion: boolean = false;

  @Action('admin/updateTermsAndConditions')
  public updateTermsAndConditions!: (payload: TermsAndConditionsValues) => void;

  @State(
    (state: RootState) => state.admin.apiState.updateTermsAndConditions.success
  )
  public updateTermsAndConditionsSuccess!: boolean;

  @Action('admin/getTermsAndConditionsByAppId')
  public getTermsAndConditionsApplication!: (appId: string) => void;

  @State((state: RootState) => state.admin.termsAndConditionsApplicationPayload)
  public tncApplicationPayload!: TermsAndConditions;

  public termsAndConditionStatus = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted'
  };

  get dayjs() {
    return dayjs;
  }

  public dateCreatedAt(date: Date | undefined) {
    return dayjs(date).format('DD MMM YYYY, h:mm A');
  }

  public viewTncDetails(items: any) {
    this.isRestoredVersion = false;
    Modal.open({
      parent: this,
      component: ViewTermsAndConditions,
      hasModalCard: true,
      trapFocus: true,
      props: {
        items,
        isRestoredVersion: true
      },
      events: {
        update: (value: TermsAndConditionEditPayload) => {
          this.$emit('update', value);
          this.$emit('close');
        }
      }
    });
  }

  public restoreTncDetails(items: any) {
    this.currentTncVersion = items;
    this.isRestoredVersion = true;
    this.getTermsAndConditionsApplication(items.application.id);
  }

  @Watch('tncApplicationPayload')
  @isDifferent
  @isTruthy
  public watchTncApplicationPayload(value: TermsAndConditions) {
    if (this.isRestoredVersion) {
      const additionalMessage =
        this.currentTncVersion.tncStatus === TermsAndConditionsStatus.ACTIVE
          ? ` <br> <br> This will automatically render the current ${value.title} applied to this application as Inactive`
          : '';

      return Dialog.confirm({
        message: `<p class="buefy-dialog-title">Restore This Version?</p><p class="buefy-dialog-content">
              Your current Terms and Conditions will be restored to the version named '${this.dateCreatedAt(
                this.currentTncVersion.createdAt
              )}'. ${additionalMessage}</p>`,
        confirmText: 'Restore',
        type: 'is-primary',
        onConfirm: () => {
          const updatedTncDetails = {
            id: this.currentTncVersion.id,
            title: this.currentTncVersion.title,
            content: this.currentTncVersion.content,
            tncStatus: this.currentTncVersion.tncStatus,
            application: this.currentTncVersion.application,
            tncParentId: this.currentTncVersion.tncParentId
          };
          this.updateTermsAndConditions(updatedTncDetails);
        }
      });
    }
  }

  public capitalizeFirstLetter(value: string) {
    if (value.indexOf(' ') >= 0) {
      const name = value.split(/\s+/);
      const administratorName: any = [];
      name.map((item: any, index) => {
        administratorName.push(item[0].toUpperCase() + item.substring(1));
      });
      return administratorName.join(' ');
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public getUserProfile(user: any) {
    let firstName: string = '';
    let lastName: string = '';
    if (user.userProfile.firstName) {
      firstName = this.capitalizeFirstLetter(user.userProfile.firstName);
    }
    if (user.userProfile.lastName) {
      lastName = this.capitalizeFirstLetter(user.userProfile.lastName);
    }

    return `${firstName} ${lastName}`;
  }

  @Watch('updateTermsAndConditionsSuccess')
  @isDifferent
  @isTruthy
  public watchUpdateTermsAndConditionSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Terms & Conditions restored successfully!`
    });
    this.$emit('update');
    this.$emit('close');
  }

  public deleteTermsAndConditions(tncId: number, title: string) {
    this.$emit('deleteTnc', { tncId, title });
  }
}
