var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.handleAddNewTermsAndCondition();
        });
      })($event)}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[(_vm.tncDetails)?_c('p',{staticClass:"modal-card-title"},[_vm._v(" Edit Terms and Condition ")]):_c('p',{staticClass:"modal-card-title"},[_vm._v("Add New Terms and Condition")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('close')}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{class:_vm.$style.container},[_c('b-field',{attrs:{"label":"Application"}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.application.id),callback:function ($$v) {_vm.$set(_vm.form.application, "id", $$v)},expression:"form.application.id"}},_vm._l((_vm.applicationOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1),_c('ValidationProvider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Title","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"input",attrs:{"placeholder":"Enter Title..","maxlength":"200","data-test":"tnc-title"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Content","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('quill-editor',{ref:"editor",attrs:{"options":_vm.editorOption,"data-test":"tnc-description"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1)]}}],null,true)}),_c('b-field',[_c('b-switch',{attrs:{"size":"is-small","data-test":"tnc-status"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggleStatus(_vm.form)}},model:{value:(_vm.form.tncStatus),callback:function ($$v) {_vm.$set(_vm.form, "tncStatus", $$v)},expression:"form.tncStatus"}},[_vm._v(" "+_vm._s(_vm.form.tncStatus ? 'Active' : 'Inactive')+" ")])],1)],1)]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.addNewTermsAndConditionsLoading },attrs:{"type":"submit","data-test":"create-tnc-button","disabled":_vm.isSubmitButtonDisabled}},[(_vm.tncDetails)?_c('span',[_vm._v("Save")]):_c('span',[_vm._v("Add")])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }