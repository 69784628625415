



















































import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilteredTermsAndConditionPayload } from '../../../store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { State } from 'vuex-class';
import { Application } from '../../../store/modules/application/types/application.types';

@Component({
  components: {}
})
export default class FilterTermsAndCondition extends Vue {
  @Prop(Object) public filterParams!: FilteredTermsAndConditionPayload;

  @State(
    ({ application }: RootState) =>
      application.productApplications?.applications
  )
  public applications!: Application[];

  get applicationOptions() {
    if (this.applications) {
      return [
        ...this.applications.map(({ id, name }) => ({
          id,
          name
        }))
      ];
    }
    return [];
  }

  @Watch('filterParams', { deep: true })
  @Debounce(500)
  public onChange() {
    this.$emit('input', this.filterParams);
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
