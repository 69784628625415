














































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { Application } from '../../../store/modules/application/types/application.types';
import { DialogProgrammatic as Dialog } from 'buefy';
import { TermsAndConditions } from '../../../store/modules/admin/types/admin.types';
import {
  TermsAndConditionsValues,
  TermsAndConditionsStatus
} from '../../../store/modules/admin/types/admin.types';
import { cloneDeep as _cloneDeep } from 'lodash';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';

const sumari: Application = {
  id: 'sumari',
  name: 'Sumari',
  description: '',
  updatedAt: '',
  createdAt: '',
  category: 'General'
};

const initialFormValue = {
  title: '',
  content: '',
  tncStatus: false,
  application: sumari
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class TermsAndConditionForm extends Vue {
  @Prop() public tncDetails!: TermsAndConditionsValues;

  @Prop() public isRestoredVersion!: boolean;

  @Action('admin/addTermsAndConditions')
  public createTermsAndConditions!: (payload: TermsAndConditionsValues) => void;

  @Action('admin/getTermsAndConditionsByAppId')
  public getTermsAndConditionsApplication!: (appId: string) => void;

  @State((state: RootState) => state.admin.termsAndConditionsApplicationPayload)
  public tncApplicationPayload!: TermsAndConditions;

  @State(
    (state: RootState) => state.admin.apiState.addTermsAndConditions.loading
  )
  public addNewTermsAndConditionsLoading!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.addTermsAndConditions.success
  )
  public addNewTermsAndConditionSuccess!: boolean;

  @State((state: RootState) => state.admin.apiState.addTermsAndConditions.error)
  public addNewTermsAndConditionError!: boolean;

  @State(
    ({ application }: RootState) =>
      application.productApplications?.applications
  )
  public applications!: Application[];

  public form: TermsAndConditionsValues = {
    ...initialFormValue
  };

  public tncInfo: TermsAndConditionsValues | undefined = undefined;

  public isSubmitButtonDisabled: boolean = true;

  get applicationOptions() {
    if (this.applications) {
      return [
        ...this.applications.map(({ id, name }) => ({
          id,
          name
        }))
      ];
    }
    return [];
  }

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      }
    };
  }

  public handleAddNewTermsAndCondition() {
    const { title, content, tncStatus, application } = this.form;
    let tncParentId: number | undefined;
    let id: number | undefined;

    if (this.tncInfo) {
      tncParentId = this.tncInfo.tncParentId
        ? this.tncInfo.tncParentId
        : this.tncInfo.id;
      id = this.tncInfo.id ? this.tncInfo.id : undefined;
    }
    this.createTermsAndConditions({
      id,
      title,
      content,
      tncStatus,
      application,
      tncParentId,
      isRestoredVersion: this.isRestoredVersion
    });
  }

  public toggleStatus(form: any) {
    this.getTermsAndConditionsApplication(form.application.id);
  }

  public mounted() {
    if (this.tncDetails) {
      this.tncInfo = _cloneDeep(this.tncDetails as TermsAndConditionsValues);
      this.form = {
        title: this.tncInfo.title,
        content: this.tncInfo.content,
        tncStatus:
          this.tncInfo.tncStatus === TermsAndConditionsStatus.ACTIVE
            ? true
            : false,
        application: this.tncInfo.application
      };
    }
  }

  @Watch('tncApplicationPayload')
  @isDifferent
  public watchTncApplicationPayload(value: TermsAndConditions) {
    if (!value) {
      return Dialog.confirm({
        message: `<p class="buefy-dialog-title">Activate ${this.form.title}?</p><p class="buefy-dialog-content">
                There is no terms and condition active. This action will make ${this.form.title} as an active terms and condition. </p>`,
        confirmText: 'Activate',
        type: 'is-primary',
        onConfirm: () => {
          this.form.tncStatus = true;
        }
      });
    } else if (!this.form.tncStatus) {
      return Dialog.confirm({
        message: `<p class="buefy-dialog-title">Activate ${this.form.title}?</p><p class="buefy-dialog-content">
                This will automatically render the current ${value.title} applied to
                this application as Inactive. </p>`,
        confirmText: 'Activate',
        type: 'is-primary',
        onConfirm: () => {
          this.form.tncStatus = true;
        }
      });
    } else if (this.tncDetails && this.tncDetails.id === value.id) {
      this.form.tncStatus = false;
      if (this.tncInfo) {
        return Dialog.alert({
          message: `<p class="buefy-dialog-title">Inactivation Unsuccessful</p><p class="buefy-dialog-content">
                    At least one instance of Terms and Conditions is needed to be active for this application. </p>`,
          confirmText: 'OK',
          type: 'is-primary',
          onConfirm: () => {
            this.form.tncStatus = true;
          }
        });
      }
    }
    if (
      (this.tncDetails && this.form.tncStatus) ||
      (!this.tncDetails && this.form.tncStatus)
    ) {
      return (this.form.tncStatus = false);
    }
  }

  @Watch('addNewTermsAndConditionSuccess')
  @isDifferent
  @isTruthy
  public watchAddNewTermsAndConditionSuccess() {
    const isTncEdited = this.tncDetails ? true : false;
    this.$emit('update', { isTncEdited, title: this.form.title });
    this.$emit('close');
  }

  @Watch('addNewTermsAndConditionError')
  @isDifferent
  @isTruthy
  public watchAddNewTermsAndConditionError() {
    return Dialog.confirm({
      message: `<p class="buefy-dialog-title">Inactivation Unsuccessful</p><p class="buefy-dialog-content">
                At least one instance of Terms and Conditions is needed to be active for this application. </p>`,
      confirmText: 'OK',
      type: 'is-primary',
      onConfirm: () => {
        this.form.tncStatus = true;
      }
    });
  }

  public closeModal() {
    this.$emit('closeModal');
  }

  public handleSubmit() {
    this.$emit('submit', this.form);
  }

  @Watch('form.title')
  @Watch('form.content')
  public watchFormValues() {
    this.isSubmitButtonDisabled = !(
      this.form.title.trim() && getHtmlContent(this.form.content).length > 0
    );
  }
}
