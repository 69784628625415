















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { DialogProgrammatic, ToastProgrammatic as Toast } from 'buefy';
import Container from '@/components/Container.vue';
import TermsAndConditionsList from './components/TermsAndConditionsList.vue';
import TermsAndConditionForm from '@/views/TermsAndConditionsManagement/components/TermsAndConditionForm.vue';
import {
  FilteredTermsAndConditionPayload,
  GetTermsAndConditionsPayload,
  GetTermsAndConditionsPayloadSort,
  Pagination,
  TermsAndConditionEditPayload,
  TermsAndConditions
} from '@/store/modules/admin/types/admin.types';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { get as _get } from 'lodash';
import FilterTermsAndCondition from './components/FilterTermsAndCondition.vue';
import { ApplicationType } from '@/store/modules/application/types/application.types';
import delay from 'delay';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

const initialState = {
  application: '',
  status: ''
};

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    TermsAndConditionsList,
    Container,
    BasePaginatorHoc,
    FilterTermsAndCondition
  }
})
export default class TermsAndConditionsManagement extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'title';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public updatedTermsAndConditionList: string[] = [];
  public filteredTermsAndConditionParams: FilteredTermsAndConditionPayload = {
    ...initialState
  };
  public deleteTermsAndConditionTitle: string = '';

  @Action('application/getApplications')
  public getApplications!: (params: {
    category?: string;
    type?: string;
  }) => Promise<void>;

  @Action('admin/getTermsAndConditions')
  public getTermsAndConditions!: (params: GetTermsAndConditionsPayload) => void;

  @Action('admin/deleteTermsAndConditions')
  public deleteTermsAndConditions!: (tncId: number) => void;

  @Action('admin/setDefaultTermsAndConditions')
  public setDefaultTermsAndConditions!: (tncId: number) => void;

  @State((state: RootState) => state.admin.termsAndConditionsPayload)
  public tncPayload!: Pagination<TermsAndConditions>;

  @State(
    (state: RootState) => state.admin.apiState.deleteTermsAndConditions.success
  )
  public deleteTermsAndConditionsSuccess!: boolean;

  @State(
    (state: RootState) =>
      state.admin.apiState.setDefaultTermsAndConditions.success
  )
  public setDefaultTermsAndConditionsSuccess!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.updateTermsAndConditions.success
  )
  public updateTermsAndConditionsSuccess!: boolean;

  get _get() {
    return _get;
  }

  get tncDetails() {
    return this.tncPayload;
  }

  get TermsAndConditionsList() {
    return TermsAndConditionsList;
  }

  @Watch('deleteTermsAndConditionsSuccess')
  @isDifferent
  @isTruthy
  public watchDeleteTermsAndConditionSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.deleteTermsAndConditionTitle} has been deleted.`
    });
    this.getTermsAndConditions({
      limit: this.perPage,
      page: this.page,
      application:
        this.filteredTermsAndConditionParams.application || undefined,
      status: this.filteredTermsAndConditionParams.status
    });
  }

  @Watch('setDefaultTermsAndConditionsSuccess')
  @isDifferent
  @isTruthy
  public watchSetDefaultTermsAndConditionsSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Terms & Conditions successfully set as default!`
    });
    this.getTermsAndConditions({
      limit: this.perPage,
      page: this.page,
      application:
        this.filteredTermsAndConditionParams.application || undefined,
      status: this.filteredTermsAndConditionParams.status
    });
  }

  public async handleDeleteTnc(payload: { tncId: number; title: string }) {
    const { tncId, title } = payload;
    this.deleteTermsAndConditionTitle = title;
    const dialogElem: Vue = DialogProgrammatic.confirm({
      message: `<p class="buefy-dialog-title">Delete ${title}?</p><p class="buefy-dialog-content">This action cannot be undone.</p>`,
      cancelText: 'Delete',
      confirmText: 'Cancel',
      type: 'is-primary',
      canCancel: ['button'],
      onConfirm: () => undefined,
      onCancel: () => {
        this.deleteTermsAndConditions(tncId);
        this.getTermsAndConditions({
          limit: this.perPage,
          page: this.page,
          application:
            this.filteredTermsAndConditionParams.application || undefined,
          status: this.filteredTermsAndConditionParams.status
        });
      }
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }

    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  public handleUpdateTnc(payload: { isTncEdited: boolean; title: string }) {
    if (payload) {
      const messageStatus = payload.isTncEdited ? 'edited' : 'created';
      Toast.open({
        queue: true,
        type: 'is-success',
        position: 'is-top',
        message: `${payload.title} has been successfully ${messageStatus}.`
      });
    }

    this.getTermsAndConditions({
      limit: this.perPage,
      page: this.page,
      application:
        this.filteredTermsAndConditionParams.application || undefined,
      status: this.filteredTermsAndConditionParams.status
    });
  }

  public handleSort(data: GetTermsAndConditionsPayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      application:
        this.filteredTermsAndConditionParams.application || undefined,
      status: this.filteredTermsAndConditionParams.status
    };

    this.getTermsAndConditions(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public handlePaginator({ perPage, page }: { perPage: number; page: number }) {
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;
    const params = {
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      application:
        this.filteredTermsAndConditionParams.application || undefined,
      status: this.filteredTermsAndConditionParams.status
    };

    this.getTermsAndConditions(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public mounted() {
    this.$router.push({
      path: this.$route.path,
      query: {
        application:
          (this.$route.query.application as string) ||
          this.filteredTermsAndConditionParams.application.toString(),
        status:
          (this.$route.query.status as string) ||
          this.filteredTermsAndConditionParams.status.toString(),
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
    this.filteredTermsAndConditionParams.application = this.$route.query
      .application as string;
    this.filteredTermsAndConditionParams.status = this.$route.query
      .status as string;
    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;
    this.handleResetQuery();
    this.getApplications({
      category: undefined,
      type: ApplicationType.TermsAndConditions
    });
  }
  public handleResetQuery() {
    const params = {
      application:
        (this.$route.query.application as string) ||
        this.filteredTermsAndConditionParams.application,
      status:
        (this.$route.query.status as string) ||
        this.filteredTermsAndConditionParams.status,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };
    return this.getTermsAndConditions(params);
  }

  public openAddNewForm() {
    this.$buefy.modal.open({
      parent: this.$root,
      component: TermsAndConditionForm,
      hasModalCard: true,
      trapFocus: true,
      events: {
        update: (value: TermsAndConditionEditPayload) => {
          this.handleUpdateTnc(value);
        }
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: FilteredTermsAndConditionPayload = {
      application: (this.$route.query.application as string) || '',
      status: this.$route.query.status as string
    };

    if (
      JSON.stringify(queryFilter) !==
      JSON.stringify(this.filteredTermsAndConditionParams)
    ) {
      this.page = 1;
      await this.getTermsAndConditions({
        application:
          this.filteredTermsAndConditionParams.application || undefined,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
        status: this.filteredTermsAndConditionParams.status
      });

      this.$router.push({
        path: this.$route.path,
        query: {
          application: this.filteredTermsAndConditionParams
            .application as string,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
          status: this.filteredTermsAndConditionParams.status as string
        }
      });
    }
  }

  public handleResetFilter() {
    this.filteredTermsAndConditionParams = { ...initialState };
  }
}
