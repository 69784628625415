import { ApiState } from '@/store/types/general.types';
import {
  ApplicationCategory,
  JAAS_APP
} from '../../../../jbi-shared/types/jaas-app.types';
import { Pagination } from '../../admin/types/admin.types';

export interface Product {
  id: number;
  name: string;
  description: string;
  durationType: string;
  duration: number;
  updatedAt: string;
  createdAt: string;
  isActive: boolean;
  isDeleted: boolean;
  licenseModels: LicenseModel[];
  application: Application;
  applicationId: JAAS_APP;
  category?: ApplicationCategory;
  currentPrice: CurrentPrice;
  chargeDetail: {
    netPrice: number;
    gstAmount: number;
  };
  redeemable?: number;
  adminOnly: boolean;
  exclusionDurationType: string;
  exclusionDuration: number;
  chargeCode: string;
}

export interface Application {
  id: string;
  name: string;
  description: string;
  updatedAt: string;
  createdAt: string;
  category: string;
}

interface CurrentPrice {
  id: number;
  amount: number;
  currency: string;
  startAt: string;
  endAt: null;
  gstApplicable: boolean;
  gst: GstPercent;
}

interface GstPercent {
  id: number;
  gstPercent: number;
  createdAt: string;
  disabledAt: string | null;
}

interface LicenseModel {
  id: number;
  value: number;
  disabledAt: null;
  applicationResource: ApplicationResource;
}

interface ApplicationResource {
  id: number;
  entityName: string;
}

export interface GetProductsPayload {
  appId?: string;
  productId?: string;
  token?: string;
}
export interface GetApplicationsPayload {
  category?: string;
  type?: string;
}

export interface GetAdministrativeProductsQuery {
  limit: number;
  page: number;
  sortColumn: string;
  sortOrder: 'ASC' | 'DESC';
  productName?: string;
  status?: string;
}

export interface InviteUserFormValues {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  productId?: number;
}

export interface ProductInvitationDto {
  firstName: string;
  lastName: string;
  email: string;
  productId?: number;
  productKey?: string;
  role_slug?: string;
}

export interface ApplicationApiState {
  getApplications: ApiState;
  getProducts: ApiState;
  getProduct: ApiState;
  getAdministrationProduct: ApiState;
  createProduct: ApiState;
  updateProduct: ApiState;
  getLicenseModels: ApiState;
  disableProduct: ApiState;
  deletedProduct: ApiState;
}

export interface ApplicationItems {
  applications: any[] | undefined;
  products: Product[] | undefined;
}
export interface ApplicationState {
  apiState: ApplicationApiState;
  productApplications: ApplicationItems | undefined;
  administrationProducts: Pagination<Product> | undefined;
  products: Product[] | undefined;
  product: Product | undefined;
  licenseModels: any[] | undefined;
  currentProduct?: string;
  currentProductStatus?: string;
}

export interface GetProductPayload {
  page: number;
  limit: number;
}

export interface FilteredProductPayload {
  productName?: string;
  status?: string;
}

export interface ProductFormValues {
  id?: number;
  appId: string;
  name: string;
  description: string;
  duration: number;
  durationType: string;
  licenseModelIds: number[];
  currency: string;
  price: number;
  adminOnly: boolean;
  gstApplicable: boolean;
  isDeleted: boolean;
  exclusionDuration: number;
  exclusionDurationType: string;
  category?: string;
  chargeCode?: string;
}

export type SubscriptionForm = Pick<
  ProductFormValues,
  'exclusionDuration' | 'exclusionDurationType'
>;

export interface DisableProductValues {
  id: number;
  isActive: boolean;
}

export interface DeleteProductValues {
  id: number;
  isDeleted: boolean;
}

export enum ApplicationType {
  General = 'General',
  TermsAndConditions = 'TermsAndConditions'
}
