
















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import dayjs from 'dayjs';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ModalProgrammatic as Modal } from 'buefy';
import ViewTermsAndConditions from './ViewTermsAndConditions.vue';
import {
  TermsAndConditionEditPayload,
  TermsAndConditions
} from '../../../store/modules/admin/types/admin.types';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import TermsAndConditionForm from '@/views/TermsAndConditionsManagement/components/TermsAndConditionForm.vue';
import ViewHistoryTermsAndConditions from './ViewHistoryTermsAndConditions.vue';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    BaseModal,
    ViewTermsAndConditions,
    SortableTableHeader
  }
})
export default class TermsAndConditionsList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Title', sortColumn: 'title', sort: true },
    { placeholder: 'Application', sortColumn: 'application', sort: true },
    {
      placeholder: 'Date Created',
      sortColumn: 'createdAt',
      sort: true
    },
    { placeholder: 'Status', sortColumn: 'status', sort: true }
  ];
  @Prop() public items!: TermsAndConditions;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  public termsAndConditionStatus = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted'
  };

  public termsAndCondtitionsTitle: string = '';

  @State(
    (state: RootState) => state.admin.apiState.getTermsAndConditions.success
  )
  public getAllTermsAndConditionsSuccess!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.getTermsAndConditionsHistory
  )
  public getTermsAndConditionsHistoryStatus!: boolean;

  @State((state: RootState) => state.admin.termsAndConditionsHistoryPayload)
  public tncHistoryPayload!: TermsAndConditions;

  @Action('admin/getTermsAndConditionsHistory')
  public getTermsAndConditionsHistory!: (tncId: number) => void;

  get dayjs() {
    return dayjs;
  }

  public viewTncDetails(items: any) {
    Modal.open({
      parent: this,
      component: ViewTermsAndConditions,
      hasModalCard: true,
      trapFocus: true,
      props: {
        items,
        isRestoredVersion: false
      },
      events: {
        update: (value: TermsAndConditionEditPayload) => {
          this.$emit('updateTnc', value);
        }
      }
    });
  }

  public updateTncDetails(items: any) {
    Modal.open({
      parent: this,
      component: TermsAndConditionForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        tncDetails: items,
        isRestoredVersion: false
      },
      events: {
        update: (value: TermsAndConditionEditPayload) => {
          this.$emit('updateTnc', value);
        }
      }
    });
  }

  public deleteTermsAndConditions(tncId: number, title: string) {
    this.$emit('deleteTnc', { tncId, title });
  }

  public viewHistoryTermsAndConditions(tncId: number, title: string) {
    this.termsAndCondtitionsTitle = title;
    this.getTermsAndConditionsHistory(tncId);
  }

  @Watch('tncHistoryPayload')
  public tncHistoryPayloadValue(value: any) {
    const currentTnc = value.find((data: any) => data.isCurrent);
    this.$buefy.modal.open({
      parent: this,
      component: ViewHistoryTermsAndConditions,
      hasModalCard: true,
      trapFocus: true,
      props: {
        items: value,
        title: currentTnc.title
      },
      events: {
        update: (item: TermsAndConditionEditPayload) => {
          this.$emit('updateTnc', item);
        }
      }
    });
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  @Watch('getTermsAndConditionsHistoryStatus.error')
  @isDifferent
  @isTruthy
  public getTermsAndConditionsHistoryError(value: any) {
    this.$buefy.modal.open({
      parent: this,
      component: ViewHistoryTermsAndConditions,
      hasModalCard: true,
      trapFocus: true,
      props: {
        items: [],
        title: this.termsAndCondtitionsTitle
      }
    });
  }
}
